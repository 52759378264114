import { ColumnFilter } from "./ColumnFilter";
import moment from "moment";

export const DOCMORTALITY = [
  {
    Header: "Date",
    Footer: "Date",
    accessor: "MortalityDate",
    Cell: ({ value }) => {
      return moment(new Date(value)).format("DD/MM/YYYY");
    },
  },
  {
    Header: "Bird Type",
    Footer: "Bird Type",
    accessor: "BirdName",
  },
  {
    Header: "Batch",
    Footer: "Batch",
    accessor: "Batch",
  },
  {
    Header: "Quantity",
    Footer: "Quantity",
    accessor: "Qty",
  },
];
