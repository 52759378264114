import React, { useState, useContext } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Typewriter from "typewriter-effect";
import { Link, animateScroll as scroll } from "react-scroll";
import newBanner from "./Photos/new-banner.png";
import heroFooter from "./Photos/hero-footer.PNG";
import ad from "./Photos/ad.png";
import { Link as Links } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { BsMouse, BsArrowRight } from "react-icons/bs";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { AuthContext } from "../helpers/AuthContext";

export const Hero = () => {
  const [isNavMenu, setIsNavMenu] = useState(false);
  const [navActive, setNavActive] = useState(false);
  const { countries, setCountries } = useContext(AuthContext);
  // const [countries, setCountries] = useState("Select Country");
  const images = [
    {
      original:
        "https://dl.airtable.com/.attachmentThumbnails/b3142d22e95e2e572ff145fe075a1f51/cece59e1",
      thumbnail:
        "https://dl.airtable.com/.attachmentThumbnails/b3142d22e95e2e572ff145fe075a1f51/cece59e1",
    },
    {
      original:
        "https://dl.airtable.com/.attachmentThumbnails/b3142d22e95e2e572ff145fe075a1f51/cece59e1",
      thumbnail:
        "https://dl.airtable.com/.attachmentThumbnails/b3142d22e95e2e572ff145fe075a1f51/cece59e1",
    },
  ];

  const activeNav = () => {
    if (window.scrollY >= 200) {
      setNavActive(true);
    } else {
      setNavActive(false);
    }
  };

  window.addEventListener("scroll", activeNav);

  return (
    <div className="hero-container">
      <div className="container-1">
        <div className="nav">
          <Link to="about-us" smooth={true} duration={2000} spy={true}>
            <p>About Us</p>
          </Link>
          <Link to="contact" smooth={true} duration={2000} spy={true}>
            <p>Contact</p>
          </Link>
          <p>Get Loan</p>
          <p>Get Insurance</p>
          <select
            name="countries"
            id="countries"
            value={countries}
            onChange={(e) => setCountries(e.target.value)}
            className="countries"
          >
            <option>{`Select Country`}</option>
            <option>NIGERIA</option>
            <option>GHANA</option>
            <option>KENYA</option>
            <option>UGANDA</option>
            <option>TANZANIA</option>
          </select>
        </div>
        <img
          src={newBanner}
          alt="banner"
          className="new-banner animate__animated animate__fadeInDown"
        />
        <img
          src={heroFooter}
          alt="Hero-Footer"
          className="hero-footer phone foot2"
        />
        <h1 className="animate__animated animate__fadeInDown">
          POULTRY FARM MANAGEMENT SOLUTION
        </h1>
        <h4 className="tool desktop">
          Tool up your farm for success...for <span>FREE!</span>
        </h4>
        <Links
          to="/register"
          className="btn-get-started animate__animated animate__fadeInDown desktop"
        >
          Get Started <BsArrowRight className="arrow-right" />
        </Links>
        <img
          src={heroFooter}
          alt="Hero-Footer"
          className="hero-footer desktop"
        />
      </div>
      <div className="container-2">
        {countries == "NIGERIA" || countries === "Select Country" ? (
          <ImageGallery
            items={images}
            showPlayButton={false}
            showThumbnails={false}
            autoPlay={true}
            showNav={false}
            slideInterval="7000"
            showFullscreenButton={false}
          />
        ) : (
          <h2 className="place-advert">{`PLACE ADVERT FOR ${countries} HERE`}</h2>
        )}
        <h4 className="tool phone">
          Tool up your farm for success...for <span>FREE!</span>
        </h4>
        <Links
          to="/register"
          className="btn-get-started animate__animated animate__fadeInDown phone"
        >
          Get Started <BsArrowRight className="arrow-right" />
        </Links>
      </div>
      {/* <Fade top>
        <div className="hero-header">
          <h1 className="hero-logo">
            afarmacco<span>&reg;</span>
          </h1>
          {!isNavMenu && (
            <AiOutlineMenu
              className="hero-menu"
              onClick={() => {
                setIsNavMenu(!isNavMenu);
              }}
            />
          )}
          {isNavMenu && (
            <AiOutlineClose
              className="hero-menu"
              onClick={() => {
                setIsNavMenu(!isNavMenu);
              }}
            />
          )}
        </div>
      </Fade>
      <div className="hero-details">
        <h1 className="animate__animated animate__fadeInDown">
          POULTRY FARM MANAGEMENT SOLUTION
        </h1>
        <Typewriter
          options={{
            autoStart: true,
            loop: true,
          }}
          onInit={(typewriter) => {
            typewriter
              .typeString(
                "The new and most efficient way to manage your POULTRY!"
              )
              .pauseFor(1500)
              .deleteChars(20)
              .typeString("track your FINANCES!")
              .pauseFor(1500)
              .deleteChars(20)
              .typeString("track your ASSETS!")
              .pauseFor(1500)
              .start();
          }}
        />
        <Links
          to="/register"
          className="btn-get-started animate__animated animate__fadeInDown"
        >
          Get Started <BsArrowRight className="arrow-right" />
        </Links>
      </div> */}
    </div>
  );
};
