import { ColumnFilter } from "./ColumnFilter";
import moment from "moment";
export const FARMHAND = [
  // {
  //   Header: "Farmer Id",
  //   Footer: "Farmar Id",
  //   accessor: "FarmerId",
  //   disableFilters: true,
  // },
  {
    Header: "Date Of Birth",
    Footer: "Date Of Birth",
    accessor: "DOB",
    Cell: ({ value }) => {
      return moment(new Date(value)).format("DD/MM/YYYY");
    },
  },
  {
    Header: "First Name",
    Footer: "First Name",
    accessor: "FirstName",
  },
  {
    Header: "Last Name",
    Footer: "Last Name",
    accessor: "LastName",
  },
  {
    Header: "State",
    Footer: "State",
    accessor: "States",
  },
  {
    Header: "Address",
    Footer: "Address",
    accessor: "Address",
  },
  {
    Header: "Mobile Phone",
    Footer: "Mobile Phone",
    accessor: "MobilePhone",
  },
  {
    Header: "Office Phone",
    Footer: "Office Phone",
    accessor: "OfficePhone",
  },
  {
    Header: "Next Of Kin",
    Footer: "Next Of Kin",
    accessor: "NOK",
  },
  {
    Header: "Guarantor",
    Footer: "Guarantor",
    accessor: "Guarantor",
  },
  {
    Header: "Guarantor Address",
    Footer: "Guarantor Address",
    accessor: "GuarantorAddress",
  },
  {
    Header: "Guarantor Mobile",
    Footer: "Guarantor Mobile",
    accessor: "GuarantorMobile",
  },
  {
    Header: "Guarantor Office",
    Footer: "Guarantor Office",
    accessor: "GuarantorOffice",
  },
];
