import React, { useState, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import "../Styles/FarmHands.css";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import { AiOutlineLeft } from "react-icons/ai";
import { LoggedOut } from "../Components/LoggedOut";
import diagnostics from "../Components/Diagnostics.xlsx";
import Nutrition from "../Components/Nutrition.xlsx";

export const ExtensionServices = () => {
  const [isExpenseForm, setIsExpenseForm] = useState(false);
  const { authState, setAuthState } = useContext(AuthContext);
  const [isNav, setIsNav] = useState(false);
  const [isFullReport, setIsFullReport] = useState(false);
  const history = useHistory();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="expenses">
      <div
        className={`${isFullReport ? "form-background" : "hide-background"}`}
        onClick={() => {
          setIsFullReport(false);
        }}
      ></div>

      {authState ? (
        <div className="expense-container">
          <div className="expense-head">
            <button className="back-btn" onClick={() => history.goBack()}>
              <AiOutlineLeft /> Go back
            </button>
            <div className="expense-heading">
              <h1>Extension Services</h1>
            </div>
            <div>
              <p> </p>
            </div>
          </div>
          <div className="extension-container">
            <a
              href={diagnostics}
              target="_blank"
              rel="noopener"
              className="diag"
            >
              Diagnostics
            </a>
            <a href={Nutrition} target="_blank" rel="noopener" className="nutr">
              Nutrition
            </a>
          </div>
        </div>
      ) : (
        <LoggedOut />
      )}
    </div>
  );
};
