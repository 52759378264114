import { ColumnFilter } from "./ColumnFilter";
import moment from "moment";

export const EXPENSE = [
  {
    Header: "Date",
    Footer: "Date",
    accessor: "ExpenseDate",
    Cell: ({ value }) => {
      return moment(new Date(value)).format("DD/MM/YYYY");
    },
  },
  {
    Header: "Invoice No",
    Footer: "Invoice No",
    accessor: "InvoiceNo",
  },
  {
    Header: "Type",
    Footer: "Type",
    accessor: "ExpenseName",
  },
  {
    Header: "Name",
    Footer: "Name",
    accessor: "HeadName",
  },
  {
    Header: "Payment Type",
    Footer: "Payment Type",
    accessor: "PmtType",
  },
  {
    Header: "Bank",
    Footer: "Bank",
    accessor: "BankName",
  },
  {
    Header: "Creditor",
    Footer: "Creditor",
    accessor: "SupplierName",
  },
  {
    Header: "Amount",
    Footer: "Amount",
    accessor: "Amount",
  },
];
