import { ColumnFilter } from "./ColumnFilter";
import moment from "moment";
export const POLLAYERMORTALITY = [
  {
    Header: "Date",
    Footer: "Date",
    accessor: "TxnDate",
    Cell: ({ value }) => {
      return moment(new Date(value)).format("DD/MM/YYYY");
    },
  },
  {
    Header: "Batch",
    Footer: "Batch",
    accessor: "Batch",
  },
  {
    Header: "Quantity",
    Footer: "Quantity",
    accessor: "Qty",
  },
];
