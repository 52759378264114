import React, { useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { HiOutlinePlus } from "react-icons/hi";

export const NoilerMeds = ({ setIsNoilerMed, returnedMeds, getCustomMeds }) => {
  const [startDate, setStartDate] = useState(0);
  const [isCustom, setIsCustom] = useState(false);
  const [isMedForm, setIsMedForm] = useState(false);
  const [med, setMed] = useState({
    MedType: "Noiler",
    Day: 0,
    Multivitamin: false,
    Antibiotics: false,
    Anticoccidiosis: false,
    Gumboro: false,
    Lasota: false,
    Deworm: false,
    Antiviral: false,
    Coryza: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "Day") {
      setMed((prevState) => ({
        ...prevState,
        [name]: parseInt(value),
      }));
      return;
    }
    setMed((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    const newDate = new Date(result).toLocaleDateString();
    return newDate;
  }

  const newMeds = async () => {
    await fetch("https://afarmacco.herokuapp.com/create/custom-meds", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Accept: "application/json",
        accessToken: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        ...med,
      }),
    }).then((res) => res.json());
    setIsMedForm(false);
    getCustomMeds();
  };

  const filteredMeds =
    returnedMeds && returnedMeds.filter((med) => med.MedType === "Noiler");

  return (
    <div className="meds animate__animated animate__zoomIn">
      <p className="btn-close med-close" onClick={() => setIsNoilerMed(false)}>
        X
      </p>
      <p className="meds-title">Noiler Medications</p>

      <div className="med-date">
        <p>Start date:</p>
        <input
          type="date"
          name="start-date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />

        {/* <p>
          {`${
            startDate
              ? "Expected end date: " + addDays(startDate, 41)
              : "Expected end date would be displayed here when you pick a start date"
          }`}
        </p> */}
      </div>
      <button
        className="default-meds-btn"
        onClick={() => setIsCustom(!isCustom)}
      >
        {isCustom ? "View default meds" : "View customized meds"}
      </button>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn-generate"
        table="table-to-xls"
        filename={`Noiler Meds from ${startDate}`}
        sheet={`Noiler Meds from ${startDate}`}
        buttonText="Download as Excel"
      />
      {!isCustom && (
        <>
          <table className="meds-table" id="table-to-xls">
            <tr>
              <th>Day</th>
              <th>Multivitamin</th>
              <th>Antibiotics</th>
              <th>Anticoccidiosis</th>
              <th>Antiviral</th>
              <th>Gumboro</th>
              <th>Lasota</th>
              <th>Deworm</th>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 0) : "1"}</td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 1) : "2"}</td>
              <td>✔</td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 2) : "3"}</td>
              <td>✔</td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 3) : "4"}</td>
              <td>✔</td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 4) : "5"}</td>
              <td></td>
              <td></td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 5) : "6"}</td>
              <td></td>
              <td></td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 6) : "7"}</td>
              <td></td>
              <td></td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 7) : "8"}</td>
              <td></td>
              <td></td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 8) : "9"}</td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 9) : "10"}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>✔</td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 10) : "11"}</td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 11) : "12"}</td>
              <td>✔</td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 12) : "13"}</td>
              <td>✔</td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 13) : "14"}</td>
              <td>✔</td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 14) : "15"}</td>
              <td>✔</td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 15) : "16"}</td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 16) : "17"}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>✔</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 17) : "18"}</td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 27) : "28"}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>✔</td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 29) : "30"}</td>
              <td></td>
              <td></td>
              <td></td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 30) : "31"}</td>
              <td></td>
              <td></td>
              <td></td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 31) : "32"}</td>
              <td></td>
              <td></td>
              <td></td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 32) : "33"}</td>
              <td></td>
              <td></td>
              <td></td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 33) : "34"}</td>
              <td></td>
              <td></td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 34) : "35"}</td>
              <td></td>
              <td></td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 35) : "36"}</td>
              <td></td>
              <td></td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 36) : "37"}</td>
              <td></td>
              <td></td>
              <td></td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 37) : "38"}</td>
              <td></td>
              <td></td>
              <td></td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 38) : "39"}</td>
              <td></td>
              <td></td>
              <td></td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 39) : "40"}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>✔</td>
            </tr>
            <tr>
              <td>{startDate ? addDays(startDate, 40) : "41"}</td>
              <td>✔</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </>
      )}

      {isCustom && (
        <>
          <div
            className="new-btn med-btn"
            onClick={() => setIsMedForm(!isMedForm)}
          >
            <div className="plus-circle">
              <HiOutlinePlus />
            </div>
            <p>New</p>
          </div>
          <table id="table-to-xls">
            <tr>
              <th>Day</th>
              <th>Multivitamin</th>
              <th>Antibiotics</th>
              <th>Anticoccidiosis</th>
              <th>Antiviral</th>
              <th>Coryza</th>
              <th>Gumboro</th>
              <th>Lasota</th>
              <th>Deworm</th>
            </tr>
            {returnedMeds &&
              filteredMeds.map((med) => {
                const {
                  Antibiotics,
                  Anticoccidiosis,
                  Antiviral,
                  Coryza,
                  Deworm,
                  Gumboro,
                  Lasota,
                  Multivitamin,
                  Day,
                  MedId,
                  MedType,
                } = med;
                return (
                  <tr key={MedId}>
                    <td>{startDate ? addDays(startDate, Day - 1) : Day}</td>
                    <td>{Multivitamin === "Administer" ? "✔" : ""}</td>
                    <td>{Antibiotics === "Administer" ? "✔" : ""}</td>
                    <td>{Anticoccidiosis === "Administer" ? "✔" : ""}</td>
                    <td>{Antiviral === "Administer" ? "✔" : ""}</td>
                    <td>{Coryza === "Administer" ? "✔" : ""}</td>
                    <td>{Gumboro === "Administer" ? "✔" : ""}</td>
                    <td>{Lasota === "Administer" ? "✔" : ""}</td>
                    <td>{Deworm === "Administer" ? "✔" : ""}</td>
                  </tr>
                );
              })}
          </table>
          {isMedForm && (
            <div className="bird-sale">
              <div className="form-bird-sale">
                <div className="input">
                  <label htmlFor="Qty">Day(1,2,3...)</label>
                  <input
                    type="number"
                    name="Day"
                    id="Qty"
                    onChange={handleChange}
                  />
                </div>
                <div className="input">
                  <label htmlFor="PolType">Antibiotics</label>
                  <select
                    name="Antibiotics"
                    id="BirdType"
                    onChange={handleChange}
                  >
                    <option></option>
                    <option>Administer</option>
                    <option>Not Administer</option>
                  </select>
                </div>
                <div className="input">
                  <label htmlFor="PolType">Anticoccidiosis</label>
                  <select
                    name="Anticoccidiosis"
                    id="BirdType"
                    onChange={handleChange}
                  >
                    <option></option>
                    <option>Administer</option>
                    <option>Not Administer</option>
                  </select>
                </div>
                <div className="input">
                  <label htmlFor="PolType">Multivitamin</label>
                  <select
                    name="Multivitamin"
                    id="BirdType"
                    onChange={handleChange}
                  >
                    <option></option>
                    <option>Administer</option>
                    <option>Not Administer</option>
                  </select>
                </div>
                <div className="input">
                  <label htmlFor="PolType">Gumboro</label>
                  <select name="Gumboro" id="BirdType" onChange={handleChange}>
                    <option></option>
                    <option>Administer</option>
                    <option>Not Administer</option>
                  </select>
                </div>
                <div className="input">
                  <label htmlFor="PolType">Lasota</label>
                  <select name="Lasota" id="BirdType" onChange={handleChange}>
                    <option></option>
                    <option>Administer</option>
                    <option>Not Administer</option>
                  </select>
                </div>
                <div className="input">
                  <label htmlFor="PolType">Deworm</label>
                  <select name="Deworm" id="BirdType" onChange={handleChange}>
                    <option></option>
                    <option>Administer</option>
                    <option>Not Administer</option>
                  </select>
                </div>
                <div className="input">
                  <label htmlFor="PolType">Antiviral</label>
                  <select
                    name="Antiviral"
                    id="BirdType"
                    onChange={handleChange}
                  >
                    <option></option>
                    <option>Administer</option>
                    <option>Not Administer</option>
                  </select>
                </div>
                <div className="input">
                  <label htmlFor="PolType">Coryza</label>
                  <select name="Coryza" id="BirdType" onChange={handleChange}>
                    <option></option>
                    <option>Administer</option>
                    <option>Not Administer</option>
                  </select>
                </div>
                <div className="new-order-wrapper">
                  <button
                    className="btn-order"
                    onClick={() => {
                      newMeds();
                    }}
                  >
                    Add
                  </button>
                  <button
                    className="btn-discard"
                    onClick={() => {
                      setIsMedForm(false);
                    }}
                  >
                    Discard
                  </button>
                </div>
              </div>
              <div className="advert">Place Advert Here</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
